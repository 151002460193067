import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Line,
  Area,
  Vertex,
  Item,
  Group
} from './export';
import * as Three from 'three';
import { GeometryUtils } from '../../utils/export';

const STYLE = {
  stroke: "#494949",
  strokeWidth: 1,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 2.613,
  fill: "#D6F0EE"
};

const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: 10,
  fontFamily: "'Courier New', Courier, monospace",
  fontWeight: "bold",
  fill: "#506ad3",
};

const HINT_STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "10px",
  fontFamily: "'Microsoft YaHei UI', Courier, monospace",
  fontWeight: "bold",
  fill: "1a75ff",
};

export default function Layer({ layer, scene, catalog }) {
  let [hide, setHide] = useState(true);
  let { unit, groups } = scene;
  let { lines, areas, vertices, holes, id: layerID, items, opacity } = layer;
  // console.log("Layer", layer.items.valueSeq());

  let allLines;
  let allLineRects;
  function point(x, y) {
    return {x, y};
  }

  allLines = GeometryUtils.getAllLines(layer);
  allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);

  let mouseOver = () =>{
    setHide(false);
  }

  let mouseLeave = () => {
    setHide(true);
  }

  let hintVertex = {
    x : 0,
    y : 0,
  };

  let angleVertexes = [];
  vertices.valueSeq().forEach(vert => {
    let vertexes = [vert];
    lines.valueSeq().forEach(line => {
      let vertex0 = layer.vertices.get(line.vertices.get(0));
      let vertex1 = layer.vertices.get(line.vertices.get(1));
      if (vertex0 === vert) vertexes.push(vertex1);
      if (vertex1 === vert) vertexes.push(vertex0);
    });
    if (vertexes.length === 3) {
      angleVertexes.push(vertexes);
    }

    hintVertex.x += vert.x;
    hintVertex.y += vert.y;

  });
  hintVertex.x /= vertices.size;
  hintVertex.y /= vertices.size;
  const itemData = [];
  const itemWall = [];
  items = items.sort(function (a, b) { return a.getIn(['properties', 'altitude', '_length']) - b.getIn(['properties', 'altitude', '_length']); });
  items.valueSeq().forEach(item => {
    if (item.layoutpos !== 'Wall') {
      if (catalog.elements.hasOwnProperty(item.type))
        itemData.push(<Item key={item.id} layer={layer} item={item} scene={scene} catalog={catalog} />);
      else {
        console.log(`Element ${item.type} does not exist in catalog.`);
      }
    }
  })

  items.valueSeq().forEach(item => {
    if(item.layoutpos === 'Wall') {
      if(catalog.elements.hasOwnProperty(item.type))
        itemWall.push(<Item key={item.id} layer={layer} item={item} scene={scene} catalog={catalog} />);
      else {
        console.log(`Element ${item.type} does not exist in catalog.`);
      }
    }
  })

  const lineData = [];
  let lineIndex = 0;
  lines.sort (
    function compare (a, b) {
        return a.createdDateTime - b.createdDateTime;
    }
  ).valueSeq().forEach((line,index) => {
    lineData.push(<Line key={line.id} layer={layer} num={index+1} line={line} allLine={allLineRects[lineIndex++]} scene={scene} catalog={catalog} />);
  })

  return (
    <g opacity={opacity}>
      {
        areas.valueSeq().map(area =>
          <Area key={area.id} layer={layer} area={area} unit={unit} catalog={catalog} />)
      }
      {
        lineData
      }
      {itemData}
      {itemWall}
      {
        vertices
          .valueSeq()
          .filter(v => v.selected)
          .map(vertex => <Vertex key={vertex.id} layer={layer} vertex={vertex} />)
      }
      {
        groups
          .valueSeq()
          .filter(g => g.hasIn(['elements', layerID]) && g.get('selected'))
          .map(group => <Group key={group.get('id')} layer={layer} group={group} scene={scene} catalog={catalog} />)
      }
      {angleVertexes.map((vertex, id) => {
        let lineGroup = vertex[0].lines;
        let angles = [];
        let thickness = [];
        let vectories = [];
        lineGroup.forEach(line => {
          line = layer.lines.get(line);
          let vertex0 = layer.vertices.get(line.vertices.get(0));
          let vertex1 = layer.vertices.get(line.vertices.get(1));
          let vec = vertex0 == vertex[0] ? new Three.Vector2(vertex1.x - vertex0.x, vertex1.y - vertex0.y) : new Three.Vector2(vertex0.x - vertex1.x, vertex0.y - vertex1.y);
          let angle = vec.angle();
          let thick = 15;
          angles.push(angle);
          thickness.push(thick);
          vectories.push(vec.normalize());
        });
        let center = new Three.Vector2(vertex[0].x, vertex[0].y);
        let pos1 = new Three.Vector2(vertex[1].x, vertex[1].y);
        let pos2 = new Three.Vector2(vertex[2].x, vertex[2].y);
        let cen = center.clone();
        let pos = pos1.clone();
        
        let vec1 = pos.sub(cen).normalize();
        pos = pos2.clone();
        let vec2 = pos.sub(cen).normalize();
        let v1 = vec1.clone(), v2 = vec2.clone();
        let vec = v1.add(v2).normalize();
        let angle = Math.abs(Math.floor((vec1.angle() * 180 / Math.PI - vec2.angle() * 180 / Math.PI) + 0.5));
        angle = angle > 180 ? 360 - angle : angle;

        let rotDir = vec1.cross(vec2) > 0; // true: rotate CCW, false: rotate CW

        // Angle Text Position

        let angleTextO = center.clone();
        let textTranslation = vec1.clone().add(vec2).normalize().multiplyScalar(12);
        angleTextO.add(textTranslation);
        angleTextO.y -= 5; // half the height of text `angle`

        //

        let tx = 0;
        let ty = 0;
        
        vec = vec.multiplyScalar(25);
        vec1 = vec1.multiplyScalar(25);
        let cen1 = center.clone(), cen2 = center.clone();
        center = center.add(vec);

        pos1 = cen1.add(vec1);
        let rotated = pos1.clone().rotateAround(cen2, angle / 2.0 * Math.PI / 180);

        let t = center.distanceTo(rotated) < 5 ? 1 : -1;
        pos2 = pos1.clone().rotateAround(cen2, t * angle * Math.PI / 180);

        // Angle SVG Path
        let anglePath = <path style={STYLE} d={`
          M ${vertex[0].x},${vertex[0].y} \
          L ${pos1.x},${pos1.y} \
          A 25,25 ${angle} 0 ${t>0?1:0} ${pos2.x},${pos2.y} z
        `} />;
        
        let length = angle;
        return (
          <g key={id} transform={`translate(${tx}, ${ty})`} 
            data-element-root
            data-prototype = 'angleChange'
            data-layer = {layer.id}
            data-selected = 'true'
            data-id = {[vertex[0].id,vertex[1].id,rotDir]}
            data-length = {length}
            onMouseOver={mouseOver} 
            onMouseLeave={mouseLeave}
          >
            {anglePath}
            <text transform={`translate(${angleTextO.x}, ${angleTextO.y}) scale(1.5, -1.5)`} style={STYLE_TEXT} >{angle}</text>
            <text transform={`translate(${hintVertex.x}, ${hintVertex.y}) scale(2, -2)`} style={{...HINT_STYLE_TEXT ,display: hide ? 'none' : 'block'}} >Click to edit angle</text>
          </g>
        );
      })}
    </g>
  );

}

Layer.propTypes = {
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
