import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { emailValidation } from '../../../../utils/email-validator';
import * as PS from '../styles';
import { DoorsConcernedMenu } from './doorstyle-menus';
import * as S from './styles';
import './styles.css';

class SubmitForQuotePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      notes: '',
      emailCheck: false,
      phoneCheck: false,

      // // For Door Styles
      // selectedDoorCategory: '',
      // selectedDoorColor: 0

      //For question
      question1: false,
      question2: false,
      question3: false,
    };
  }

  setEmailAndPhone() {
    const email = localStorage.getItem('email');
    const phone = localStorage.getItem('phone');
    if (email !== null) {
      this.setState({ email });
    }
    if (phone !== null) {
      this.setState({ phone });
    }
  }

  componentDidMount() {
    this.setEmailAndPhone();
  }

  /**
   * Get door information
   * @param {{success:boolean,data}} categoryData Category data from server
   * @param {number} doorColorId Door color id
   * @returns {[selectedDoorCategory,selectedDoorStyle,selectedDoorColor,selectedDoorColorItem]} Door tree information
   */
  getDoorInfo(categoryData, doorColorId) {
    if (categoryData.success) {
      let { data: { doorStyles: doorStyleProp } } = categoryData;

      // According to door style id
      // Find out the name of door category & door style
      let styleItem = null;
      let colorItem = null;
      let categoryItem = doorStyleProp.items.find(category => {
        let tempStyleItem = category.items.find(style => {
          let item = style.items.find(it => it.id === doorColorId);
          if (item) {
            colorItem = item;
            return true;
          } else {
            return false;
          }
        });
        if (tempStyleItem) {
          styleItem = tempStyleItem;
        }
        return !!tempStyleItem;
      });

      return [categoryItem.name, styleItem.name, doorColorId, colorItem];
    }
    return null;
  }

  componentWillReceiveProps(newProps) {
    // When visible
    if (newProps.visible && !this.props.visible) {
      this.setEmailAndPhone();
    }

    // Check for curDoorStyle change
    if ((this.props.curDoorStyle !== newProps.curDoorStyle) && (newProps.curDoorStyle !== null)) {
      if (newProps.curDoorStyle !== undefined) {
        let doorColorId = newProps.curDoorStyle.id !== undefined ? newProps.curDoorStyle.id : newProps.curDoorStyle.get('id');
        let { categoryData } = this.props;
        let info = this.getDoorInfo(categoryData, doorColorId);
        this.setState({
          selectedDoorCategory: info[0],
          selectedDoorColor: info[2]
        });
      }
    }
  }

  _submit() {
    const { email, phone, emailCheck, phoneCheck, selectedDoorCategory, selectedDoorColor, notes, question1, question2, question3 } = this.state;
    let info = this.getDoorInfo(this.props.categoryData, selectedDoorColor);
    let colorItem = info[3];
    let selectedDoorStyle = info[1];

    if (email === '' && emailCheck || phone === '' && phoneCheck || !emailValidation(email)) {
      let msg;
      if (email === '' && emailCheck) msg = 'Type your email address';
      else if (phone === '' && phoneCheck) msg = 'Type your phone number';
      else if (!emailValidation(email) && emailCheck) msg = 'Type your valid email address';
      alert(msg);
      return;
    }

    let questions = []; 
    if(question1) questions.push("I need help determining how many fillers and molding pieces I need.");
    if(question2) questions.push("I want someone to build my cart so I can checkout on your website.");
    if(question3) questions.push("I have general questions about my design or quote.");
    if(this)
    this.props.submitForQuote({
      email,
      emailCheck,
      phone, 
      phoneCheck,
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      products: JSON.parse(localStorage.getItem('usedObjects')),
      selectedDoorCategory,
      selectedDoorColor: `${colorItem.name}/${colorItem.thumbnail}`,
      selectedDoorStyle,
      questions,
      notes
    });
    this.closePanel();
  }
  closePanel() {
    this.props.handleSubmitForQuote(false);
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({ phone: (e.target.validity.valid) ? e.target.value : this.state.phone });
  }

  handleCheckChange  ( event ) {
    this.setState({ [event.target.value]: event.target.checked });
  };

  render() {
    let { visible, categoryData } = this.props;
    let { email, phone, emailCheck, phoneCheck, selectedDoorCategory, selectedDoorColor, notes } = this.state;

    let doorCategories = [];
    let doorStyles = [];
    let doorColors = [];

    // Get door concerned data
    if (categoryData.success) {
      let { data: { doorStyles: doorStyleProp } } = categoryData;
      // Get door categories
      doorCategories = doorStyleProp.items;

      // Get door styles
      let doorStylesItem = doorCategories.find(it => it.name === selectedDoorCategory);
      if (doorStylesItem !== undefined) {
        doorStyles = doorStylesItem.items;

        // Get door colors
        doorColors = doorStyles.map(doorStyle => doorStyle.items).flat();
      }
    }

    return (
      <div
        className='submit-for-quote'
        style={{ display: visible ? 'block' : 'none' }}
      >
        <S.Div>
          <div className='submit-for-quote-header'>
            <div
              className='submit-for-quote-header-icon'
              onClick={this.closePanel.bind(this)}
            >
              <PS.IconButton><FaTimes /></PS.IconButton>
            </div>
          </div>
          <div className='submit-for-quote-desc'>
            Your project will be sent to the Design Department for review.<br />
            What is the best way to reach you for the next steps?
          </div>
          <div className='submit-for-quote-form'>
            <div className='submit-for-quote-form-item'>
              <PS.DialogCheckbox
                checked={ this.state.emailCheck }
                onClick = { (e) => this.handleCheckChange (e) }
                value="emailCheck"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label className="submit-for-quote-form-item-label">E-mail:</label>
              <input
                disabled={!emailCheck}
                onChange={e => this.handleEmailChange(e)}
                className='submit-for-quote-form-item-input'
                value={email ? email : ''}
                required
              />
            </div>
            <div className='submit-for-quote-form-item'>
              <PS.DialogCheckbox
                checked={ this.state.phoneCheck }
                onClick = { (e) => this.handleCheckChange (e) }
                value="phoneCheck"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label className="submit-for-quote-form-item-label">Phone:</label>
              <input
                value={phone ? phone : ''}
                className='submit-for-quote-form-item-input'
                onChange={e => this.handlePhoneChange(e)}
                disabled={!phoneCheck}
                pattern={"[0-9]*"}
                maxLength={50}
              />
            </div>
          </div>
          <div className='submit-for-quote-desc'>
            Specify how our designers may further assist you:
          </div>
          <div className='submit-for-quote-form'>
            {/* <div className='submit-for-quote-form-item'>
              <span className='submit-for-quote-form-item-span'>
                Door Category:
              </span>
              <DoorsConcernedMenu
                selected={selectedDoorCategory}
                data={doorCategories}
                onChange={v => {
                  let category = doorCategories.find(it => it.name === v);
                  let color = category.items[0].items[0];
                  this.setState({
                    selectedDoorCategory: v,
                    selectedDoorColor: color.id
                  });
                }}
              />
            </div>
            <div className='submit-for-quote-form-item'>
              <span className='submit-for-quote-form-item-span'>
                Finish:
              </span>
              <DoorsConcernedMenu
                data={doorColors}
                getValue={it => it.id}
                selected={selectedDoorColor}
                onChange={v => {
                  this.props.setDoorStyle(
                    doorColors.filter(item => {
                      if (item.id === v) return item;
                    })[0]
                  );
                  this.setState({ selectedDoorColor: v });
                }}
              />
            </div> */}
            <div className='submit-for-quote-form-item'>
              <PS.DialogCheckbox
                checked={this.state.question1}
                onClick={(e) => this.handleCheckChange(e)}
                value="question1"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '10px'
                }}
              >
                I need help determining how many fillers and molding pieces I need.
              </label>
            </div>
            <div className='submit-for-quote-form-item'>
              <PS.DialogCheckbox
                checked={this.state.question2}
                onClick={(e) => this.handleCheckChange(e)}
                value="question2"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '10px'
                }}
              >
                I want someone to build my cart so I can checkout on your website.
              </label>
            </div>
            <div className='submit-for-quote-form-item'>
              <PS.DialogCheckbox
                checked={this.state.question3}
                onClick={(e) => this.handleCheckChange(e)}
                value="question3"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '10px'
                }}
              >
                I have general questions about my design or quote.
              </label>
            </div>
            <div className='submit-for-quote-form-item'>
              Notes:
              <div>
                <textarea
                  value={notes}
                  className="submit-for-quote-form-item-textarea"
                  rows={10}
                  onChange={e => this.setState({ notes: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div
            className='submit-for-quote-footer'
            onClick={this._submit.bind(this)}
          >
            <img
              width={80}
              style={{ float: 'right' }}
              src={'/assets/img/svg/toolbar/use_button.svg'}
            />
            <S.ButtonTitle>SUBMIT</S.ButtonTitle>
          </div>
        </S.Div>
      </div>
    )
  }
}


export default SubmitForQuotePopup
