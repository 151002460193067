import React from 'react';
import * as S from './styles';
import * as PS from '../styles';
import './styles.css';
import { FaTimes } from 'react-icons/fa';

class NewProjectPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asking: localStorage.getItem('asking')?localStorage.getItem('asking'):false
    };
  }
  
  _submit () {
    if(this.state.asking) localStorage.setItem('asking', true);
    this.props.newProject();
    this.closePanel();
  }
  closePanel () {
    this.props.handleNewProject(false);
  }
  render() {
    let { visible } = this.props;
    let { asking } = this.state;
    return (
      <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
        <S.Div>
          <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
            <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
              <PS.IconButton><FaTimes /></PS.IconButton>
            </div>
          </div>
          <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
            Would you like to start a new project?
          </div>
          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative' }}>
          </div>
          <div style={{ display: 'flex', position: 'relative', marginTop : '20px'}}>
            <div style={{ width: '70px', marginLeft: '80px', marginTop: '15px', cursor: 'pointer' }} onClick={() => this._submit()}>
              <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
              <S.ButtonTitle1>
                {'OK'}
              </S.ButtonTitle1>
            </div>
            <div style={{ width: '70px', marginLeft: '40px', marginTop: '15px', cursor: 'pointer' }} onClick={() => this.closePanel()}>
              <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/cancel_button.svg'} />
              <S.ButtonTitle2>
                {'CANCEL'}
              </S.ButtonTitle2>
            </div>
          </div>
        </S.Div>
      </div>
    )
  }
}


export default NewProjectPopup
