import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MODE_IDLE_3D
} from '../../../constants';
import * as S from './styles';
import { Tooltip } from '@material-ui/core/index';

export default class ToggleMeasureButton extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props, } = this;
    let overlayStyle = {}
    // let activeStyle = {}
    let activeStyle = props.state.scene.showMeasure == 0 ? {position: 'absolute', left: 270, top: 14}:{position: 'absolute', left: 242, top: 14};
    return (
      <Tooltip placement="top" title={props.state.scene.showMeasure == 0 ? "Show Measure" : "Hide Measure"}>
        <S.Toggle style={{...overlayStyle, color: "black"}} onClick={props.onClick}>
            {props.backgroundImage}
            <span style={{position: 'absolute', left: 250}}><strong>S</strong></span>
            <span style={{position: 'absolute', left: 278}}><strong>H</strong></span>
          {
            [
              props.icon !== undefined ? <S.ToggleIcon style={{...activeStyle}} key={props.icon}>{props.icon}</S.ToggleIcon>:null
            ]
          }
        </S.Toggle>
      </Tooltip>
    )
  }
}

ToggleMeasureButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
