import React from 'react';
import * as S from './styles';
import * as PS from '../styles';
import { FaTimes } from 'react-icons/fa';
import './savedesign.style.css';
import { VelocityTransitionGroup } from 'velocity-react';
import { PROJECT_NAME_LENGTH_LIMIT } from '../../../../constants';
import { emailValidation } from '../../../../utils/email-validator';

class SaveDesignPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      projectName: '',
      notes: '',
      phone: '',

      // error handling
      pjNameLimit: false
    };

    this.timeoutId = null;

    functionBinder.call(this);

    function functionBinder() {
      this.onSave = this.onSave.bind(this);
      this.closePanel = this.closePanel.bind(this);
      this.projectNameChangeHandler = this.projectNameChangeHandler.bind(this);
    }
  }

  componentWillReceiveProps(newProps) {
    const email = localStorage.getItem('email');
    let projectName = localStorage.getItem('projectTitle');
    if (newProps.state.scene.title !== "") {
      projectName = newProps.state.scene.title || localStorage.getItem('projectTitle');
    }
    
    if ((newProps.visible !== this.props.visible) && newProps.visible) {
      if (email !== null) {
        this.setState({ email: email });
      }
      if (projectName !== null) {
        this.setState({ projectName });
      }
    }
  }

  onSave() {
    const { firstName, lastName, email, projectName, phone } = this.state;
    if (firstName === '' || lastName === '' || email === '' || projectName === '' || !emailValidation(email) || phone === '') {
      let msg;
      if (firstName === '') msg = 'Type your first name';
      else if (lastName === '') msg = 'Type your last name';
      else if (email === '') msg = 'Type your email address';
      else if (phone === '') msg = 'Type your phone number';
      else if (projectName === '') msg = 'Type your project name';
      else if (!emailValidation(email)) msg = 'Type your valid email address';
      alert(msg);
      return;
    }
    this.props.saveProject(this.state);
    this.closePanel();
  }

  closePanel() {
    this.props.handleSaveDesign(false);
    this.setState({ firstName: '', lastName: '', email: '', projectName: '', notes: '', phone: '' });
  }

  projectNameChangeHandler(e) {
    if (e.target.value.length >= PROJECT_NAME_LENGTH_LIMIT) {
      this.setState({ pjNameLimit: true });
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.setState({ pjNameLimit: false }), 2000);
    } else {
      this.setState({ projectName: e.target.value });
    }
  }

  render() {
    let { visible } = this.props;
    let { firstName, lastName, email, projectName, notes, phone } = this.state;
    return (
      <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
        <S.Div>
          <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
            <div style={{ float: 'right', marginTop: '4px' }} onClick={this.closePanel}>
              <PS.IconButton><FaTimes /></PS.IconButton>
            </div>
          </div>
          <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
            Saving your project will enable you to retrieve it later.<br />
            Leave your information below to receive a direct link by email
          </div>
          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <div style={{ paddingTop: '20px', width: '360px' }}>
              First Name: <span style={{ color: 'red' }}>*</span>
              <input value={firstName} style={{ float: 'right', width: '250px' }} onChange={e => this.setState({ firstName: e.target.value })} required />
            </div>
            <div style={{ paddingTop: '20px', width: '360px' }}>
              Last Name: <span style={{ color: 'red' }}>*</span>
              <input value={lastName} style={{ float: 'right', width: '250px' }} onChange={e => this.setState({ lastName: e.target.value })} required />
            </div>
            <div style={{ paddingTop: '20px', width: '360px' }}>
              E-mail Address: <span style={{ color: 'red' }}>*</span>
              <input value={email} style={{ float: 'right', width: '250px' }} onChange={e => this.setState({ email: e.target.value })} required />
            </div>
            <div style={{ paddingTop: '20px', width: '360px' }}>
              Phone Number: <span style={{ color: 'red' }}>*</span>
              <input value={phone} style={{ float: 'right', width: '250px' }} onChange={e => this.setState({ phone: (e.target.validity.valid) ? e.target.value : phone })} pattern={"[0-9]*"} maxLength={50}/>
            </div>
            <div className='savedesign-row'>
              Project Name: <span style={{ color: 'red' }}>*</span>
              <input
                value={projectName}
                style={{ float: 'right', width: '250px' }}
                onChange={this.projectNameChangeHandler}
                required
              />
              <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }}>
                {this.state.pjNameLimit && (
                  <div className='savedesign-warning'>{`Project Name should be less than ${PROJECT_NAME_LENGTH_LIMIT} characters!`}</div>
                )}
              </VelocityTransitionGroup>
            </div>
            <div style={{ paddingTop: '20px', width: '360px' }}>
              Notes:
              <div style={{ marginTop: '8px' }}>
                <textarea value={notes} style={{ width: '100%', height: '100%', resize: 'none' }} rows={10} onChange={e => this.setState({ notes: e.target.value })} />
              </div>
            </div>
          </div>
          <div style={{ width: '70px', marginLeft: '360px', marginTop: '10px', cursor: 'pointer' }} onClick={this.onSave}>
            <img width={'70px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
            <S.ButtonTitle>
              {'SAVE'}
            </S.ButtonTitle>
          </div>
        </S.Div>
      </div>
    )
  }
}


export default SaveDesignPopup
