import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as SharedStyle from '../../../shared-style';

export const PanelWrapper = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  margin-left: 85px;
  width: 310px;
  top: 370px;
  background-color: ${SharedStyle.TOOLBAR_COLOR.focus};
`;

export const Title = styled.p`
  float: left;
  font-size: 80%;
  padding-left: 8px;
`;

export const Head = styled.p`
  display: inline-block;
  float: right;
  padding-right: 12px;
  font-size: 80%;
`;

export const Close = styled.a`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: this.state.closeover ? black : white;
  background-color: ${SharedStyle.MATERIAL_COLORS['500'].red};
  cursor: pointer;
  display: inline-block;
  text-align: center;
`

export const hintText = styled.p`
  position: fixed;
  top: 65px;
  background-color: #222222;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
  z-index: 11;
  color: white;
  font-weight: 500;
  left: calc(50% - 136px);
`

export const Direction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  // position: fixed;
  cursor: pointer;
  // background-color: #fff;
  color: white;
  border-radius:32px;
  margin-right: 15px;
  right:260px;
  bottom:25px;
  :hover{
    border-radius: 40px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
  }
  :active {
    box-shadow: none !important;
  }
`
export const TakePictureDialog = styled(Dialog)`
  .never-show-check {
      color: white;
  }
  .never-show-check > .MuiTypography-body1 {
      font-size: 0.7rem;
  }
  .never-show-check .MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
  }
  .never-show-check .MuiCheckbox-colorPrimary.Mui-checked {
      color: white;
  }
  .t_modal {
      z-index: 0 !important;
  }
  .MuiDialogTitle-root {
      background-color:#41403F  !important;
  }
  .MuiDialogContent-root {
      background-color: #41403F    !important;
      margin: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-variant: tabular-nums;
      line-height: 1.5715;
      background-color: #fff;
      -webkit-font-feature-settings: 'tnum';
              font-feature-settings: 'tnum';
  }
  .MuiDialog-root p {
      margin-top: 0;
      margin-bottom: 1em;
  }
  h1, h2, h3, h4, h5, h6 {
      /* margin-top: 0; */
      /* margin-bottom: 0.5em; */
      color: rgba(255, 255, 255, 0.85);
      /* font-weight: 500; */
  }
  .MuiButton-textPrimary {
      color: white !important;
  }
  .MuiDialog-paperWidthSm {
      min-width: 90vw !important;
      height: 95vh !important;
  }
`;