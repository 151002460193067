import styled from 'styled-components';

// Common Styles
export const IconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 5px 5px 2px 5px;
  cursor: pointer;
`

export const ActiveIconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #0f8a61;
  border: 1px solid #0f8a61;
  padding: 5px 5px 2px 5px;
  cursor: pointer;
`

// Main Styles
export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
`

export const TitleBar = styled.div`
  width: 100%;
  height: 35px;
  background: #fff;
  font-size: 16px;
  color: #1b1b1b;
  padding: 0px 8px 0px 8px;
  clear: both;
`

export const Title = styled.div`
  float: left;
  line-height: 32px;
  color: #FFF;
  font-size: 12px;
`

export const SelectTitle = styled.span`
  position: absolute;
  top: 51%;
  left: 0px;
  width: 100%;
  color: #FFF;
  transform: translate(0%, -50%);
  font-size: 13px;
`

export const TitleBarIconButton = styled.div`
  float: left;
  margin-left: 10px;
  margin-top: 15px;
  cursor: pointer;
  :hover{
    font-size:20px;
  }
`

export const ContentWrapper = styled.div`
  text-align: left;
  clear: both;
  padding: 10px 15px;
`

export const SearchForm = styled.div`
  position: relative;
  margin: 0px 5px;
`

export const SearchInput = styled.input`
  background: #FFF;
  border: 0px;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  padding-left: 24px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px;
  }
`

export const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 5px;
  color: #666;
  cursor: pointer;
  font-size: 15px;
`

export const Products = styled.div`
`

export const ProductsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: streatch;
`
export const TitlesRow = styled.div`
  display: flex;
`

export const RecentTitle = styled.div`
  color: #FFF;
  margin: 14px 0px 19px;
`

export const RecentStyles = styled.div`
`

export const AddStyleButtonWrapper = styled.div`
  cursor: pointer;
  color: #FFF;
  button {
    float: left;
  }
  span {
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    font-size: 10px;
  }
`

export const SubTitle = styled.div`
  color: #1b1b1b;
  cursor: pointer;
  padding: 10px 5px;
  :hover{
    background: #ababab;
  }
`
export const ChildTitle = styled.div`
  color: #000;
  padding-bottom:10px;
`
export const CabinetTitle = styled.div`
  color: #000;
  font-weight: 800;
`
export const PropteryValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-bottom:10px;
  span {
    font-weight:100;
  }
`
export const PositionValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-top:10px;
  span {
    font-weight:100;
  }
`
export const Diver = styled.div`
  border-bottom: 1px solid #888;

`
export const FurnitureBoxTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
  font-size: 11px;
`


export const ClearFix = styled.div`
  clear: both;
  height: 0px;
`
export const Image = styled.div`
  text-align: center;
  div{
    width: 66px;
    height: 70px;
  }
  img {
    width: 66px;
    height:70px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  :hover{
    img {
      width:70px;
      height:75px;
    }
  }
`
export const SelectedWrapper = styled.div`
  position: absolute;
  left:101px;
  width: 180px;
  background: #fff;
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const SelectedBox = styled.div`
  cursor: pointer;
  position:relative;
  :hover{
    img {
        padding: 8px;
        transition: 0.3s;
        color: rgba(0, 0, 0, 0.87);
        padding: 0;
        font-size: 1.4rem;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 600;
        line-height: 1.75;
        text-transform: uppercase;
    }
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`

export const EditBox = styled.div`
  cursor: pointer;
  :hover{
    img {
        transition: 0.3s;
        color: rgba(0, 0, 0, 0.87);
        padding: 0;
        font-size: 1.4rem;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 600;
        line-height: 1.75;
        text-transform: uppercase;
    }
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`
export const InputStyle = styled.div`
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
`
