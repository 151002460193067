import React from 'react';
import { IconButton } from '@material-ui/core';
import { EditOutlined, SaveOutlined } from '@material-ui/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import * as Icon from 'react-icons/fa';
import Popup from "reactjs-popup";
import { Project } from '../../class/export';
import { API_SERVER_URL, DESGINER_ASSISTANCE, KEYBOARD_BUTTON_CODE, MODE, NEW_DESIGN, SAVE_DESIGN, SUBMIT_FOR_QUOTE } from '../../constants';
import { browserUpload } from '../../utils/browser';
import { FormTextInput } from '../style/export';
import './header.style.css';
import * as PS from './styles';

const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

const headerBarStyle = {
  position: 'absolute',
  top: 0,
  lineHeight: '17px',
  // fontSize: '12px',
  backgroundColor: "rgb(250 252 251)",
  borderBottom: "1px solid #e8e8e8",
  display: "flex",
  justifyContent: "space-between",
  // backgroundColor: SharedStyle.SECONDARY_COLOR.alt,
  boxShadow: '3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  backgroundColor: '#fff',
  padding: '3px 1em',
  textAlign: "center",
  border: 0,
  margin: 0,
  boxSizing: 'border-box',
  cursor: 'default',
  userSelect: 'none',
  zIndex: '199'
};
const inputStyle = { textAlign: 'left', height: '32px' };
const uploadFiles = (data, filePath) => {
  var p1 = new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filePath,
      Body: data,
      ContentType: "application/json",
      ACL: 'public-read'
    };
    s3.putObject(params, function (err, data) {
      if (err) {
        reject(err);
        return;
      }
      console.log("success");
      resolve([data.Location]);
    });
  });
  return p1;
};
export default class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { HamburgerMenuOpen: false, renameForm: false, renameTitle: "  ", saveAsBtnClicked: false };
  }

  canSubmit() {
    return this.state.renameTitle !== '';
  };

  renameFormToggle(data, saveAsBtnClicked = false) {
    if (this.canSubmit()) {
      this.setState({
        renameForm: data,
        saveAsBtnClicked
      })
    } else {
      alert("This field is required");
    }
  };

  onRenameTitleChange(ev) {
    this.setState({ renameTitle: ev.target.value });
    localStorage.setItem('projectTitle', ev.target.value);
    this.context.projectActions.rename (ev.target.value);
  };

  renameTitleSubmit(ev) {
    ev.preventDefault();
    if (!this.canSubmit()) {
      this.renameFormToggle(false);
      return;
    }
    this.setState({
      renameForm: false,
      renameTitle: this.state.renameTitle,
    });
  };
  handlePopupClose(e) {
    const { HamburgerMenuOpen } = this.state;
    this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
  }

  render() {
    let {
      props: { state, width, height, saveProject: _saveProject, newProject, handleSaveDesign, handleSubmitForQuote, handleDesignerAssist, handleNewProject, isModalVisible },
      context: { projectActions, userActions, translator }
    } = this;

    const { HamburgerMenuOpen, renameTitle, renameForm, saveAsBtnClicked } = this.state;
    let userInfo = [];
    if (this.props.match) userInfo = this.props.match.params;
    // let projectTitle = localStorage.getItem('projectTitle');
    let projectTitle = state.scene.title;
    let projectId = localStorage.getItem('projectId');

    if (projectTitle == null || projectTitle == "") {
      localStorage.setItem('projectTitle', "Project Name");
      this.context.projectActions.rename ("Project Name");
      projectTitle = "Project Name";
    }

    let handleSaveBtn = () => {
      if (localStorage.getItem('projectId') === null) { // If it's a new project, Show save dialog
        handleSaveDesign(true);
      } else { // It's a saved project, Save the changed project
        this.props.saveProject({ projectName: state.scene.title, projectId: localStorage.getItem('projectId'), email: localStorage.getItem('email') });
      }
    };

    let newProjectBtn = () => {
      if (localStorage.getItem('asking')) newProject();
      else handleNewProject(true);
    };

    let saveProject = e => {
      e.preventDefault();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
      _saveProject();
    };

    let saveAsBtnClickEvent = e => {
      e.preventDefault();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
      projectActions.unselectAll()
      this.renameFormToggle(true, true);
    };

    let snaplibrary = e => {
      e.preventDefault();
      projectActions.openCatalog();
      this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen })
    };

    let objectProperty = "";

    if (HamburgerMenuOpen) {
      objectProperty = (<PS.SelectedWrapper>
        <PS.TitleBar>
          <PS.TitleBarIconButton onClick={e => { this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen }) }}>
            <Icon.FaTimes />
          </PS.TitleBarIconButton>
        </PS.TitleBar>
        <PS.ContentWrapper>
          <PS.SubTitle onClick={e => { this.props.history.push('/login'); userInfo.role !== undefined && userActions.logout(); }}>
            {userInfo.role === undefined ? 'Sign in' : 'Sign out'}
          </PS.SubTitle>
          <PS.SubTitle onClick={e => { this.props.history.push({ pathname: '/register', state: { type: 'register' } }); }}>
            {'Register'}
          </PS.SubTitle>
          {userInfo.role === 'admin' || userInfo.role === 'superadmin' &&
            (<PS.SubTitle onClick={e => { this.props.history.push({ pathname: '/register', state: { type: 'adduser' } }); }}>Add user</PS.SubTitle>)}
          <PS.Diver></PS.Diver>
          <PS.SubTitle onClick={event => {
            this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen });
            confirm('Would you want to start a new Project?')
              ? (projectActions.newProject(), this.renameFormToggle(false), localStorage.removeItem('projectId'), localStorage.setItem('projectTitle', "Project Name"), projectActions.rename ("Project Name"))
              : null
          }}>New Project</PS.SubTitle>
          <PS.Diver></PS.Diver>
          {userInfo.role === 'designer' || userInfo.role === 'customer' || userInfo.role === 'superdesigner' || userInfo.role === 'superadmin' ? (<PS.SubTitle onClick={saveProject}>Save Project</PS.SubTitle>) : <PS.SubTitle onClick={event => { this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen }); alert('You should login.') }}>Save Project</PS.SubTitle>}
          <PS.Diver></PS.Diver>
          {userInfo.role === 'designer' || userInfo.role === 'customer' || userInfo.role === 'superdesigner' || userInfo.role === 'superadmin' ? (<PS.SubTitle onClick={saveAsBtnClickEvent}>Save As...</PS.SubTitle>) : <PS.SubTitle onClick={event => { this.setState({ HamburgerMenuOpen: !HamburgerMenuOpen }); alert('You should login.') }}>Save As...</PS.SubTitle>}

          <PS.Diver></PS.Diver>
          {userInfo.role === 'designer' || userInfo.role === 'customer' || userInfo.role === 'superdesigner' || userInfo.role === 'superadmin' ? (<PS.SubTitle onClick={snaplibrary}>Open Project</PS.SubTitle>) : null}
          {/* <PS.PositionValue>Rotate</PS.PositionValue>
          <PS.PositionValue>Resize</PS.PositionValue> */}
        </PS.ContentWrapper>
      </PS.SelectedWrapper>);
    }
    return (
      <div style={{ ...headerBarStyle, width, height }}>
        <div style={{ margin: "10px 15px", display: "flex" }} >
          <PS.SelectedBox>
            <a href="https://www.thertastore.com" target="_blank">
              <img width="42px" src="/assets/img/rta/rta_logo_box_blue.jpg" />
            </a>
          </PS.SelectedBox>
          <Popup trigger={objectProperty} open={this.state.HamburgerMenuOpen} arrow={false} />
        </div>
        {
          renameForm
            ? (
              <div className='header-projectname-wrapper'>
                <PS.InputStyle>
                  <FormTextInput
                    value={projectTitle ? projectTitle : renameTitle}
                    onChange={e => { this.onRenameTitleChange(e) }}
                    style={inputStyle}
                    onKeyDown={e => {
                      var keyCode = e.keyCode || e.which;
                      if (keyCode == KEYBOARD_BUTTON_CODE.ENTER) {
                        if (saveAsBtnClicked) {
                          saveProject(e);
                        }
                        this.renameFormToggle(false, false);
                      }
                    }}
                  />
                </PS.InputStyle>
                <PS.EditBox onClick={(e) => {
                  if (saveAsBtnClicked) {
                    saveProject(e);
                  }
                  this.renameFormToggle(false, false);
                }}
                >
                  <IconButton className='padding-5'><SaveOutlined /></IconButton>
                </PS.EditBox>
              </div>
            )
            : (
              <div className='header-projectname-wrapper'>
                <div>
                  <p className='header-projectname-display'>
                    {projectTitle ? projectTitle : renameTitle}
                  </p>
                </div>
                <PS.EditBox onClick={isModalVisible ? null : () => this.renameFormToggle(true, saveAsBtnClicked)}>
                  <IconButton className='padding-5'><EditOutlined /></IconButton>
                </PS.EditBox>
              </div>
            )
        }
        <div style={{ margin: "17px 15px", display: "flex" }}>
          <PS.SelectedBox
            style={{ marginRight: "10px" }}
            onClick={isModalVisible ? null : newProjectBtn}
          >
            <img height="30px" src="/assets/img/svg/headerbar/review_quote.svg" />
            <PS.SelectTitle>
              {NEW_DESIGN}
            </PS.SelectTitle>
          </PS.SelectedBox>
          <PS.SelectedBox
            style={{ marginRight: "30px" }}
            onClick={isModalVisible ? null : handleSaveBtn}
          >
            <img height="30px" src="/assets/img/svg/headerbar/review_quote.svg" />
            <PS.SelectTitle>
              {SAVE_DESIGN}
            </PS.SelectTitle>
          </PS.SelectedBox>
          {
            <PS.SelectedBox
              style={{ marginRight: "10px" }}
              onClick={isModalVisible ? null : () => {
                if (userInfo.pid === 'new')
                  handleSaveDesign(true);
                else handleSubmitForQuote(true);
              }}
            >
              <img width="142px" src="/assets/img/svg/headerbar/consult_designer.svg" />
              <PS.SelectTitle>
                {SUBMIT_FOR_QUOTE}
              </PS.SelectTitle>
            </PS.SelectedBox>
          }
          {
            <PS.SelectedBox
              onClick={isModalVisible ? null : () => handleDesignerAssist(true)}
            >
              <img width="142px" src="/assets/img/svg/headerbar/consult_designer.svg" />
              <PS.SelectTitle>
                {DESGINER_ASSISTANCE}
              </PS.SelectTitle>
            </PS.SelectedBox>
          }

        </div>
      </div>
    )
  }
}

Header.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Header.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
