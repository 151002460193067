import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../shared-style';
import * as S from './styles';
import { Tooltip } from '@material-ui/core/index';

export default class DirectionPanSpinButton extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props } = this;
    let overlayStyle = {}
    // overlayStyle = props.active ? {...overlayStyle,'background': SharedStyle.TOOLBAR_COLOR.active}:{...overlayStyle};
    return (
      <Tooltip title={props.tooltip ? props.tooltip : ''} placement="top">
        <S.DirectionSpinIcon style={props.style} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp} key={props.icon}>{props.icon}</S.DirectionSpinIcon>
      </Tooltip>
    )
  }
}

DirectionPanSpinButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
