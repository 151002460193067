import React, { Component } from 'react';
import * as SharedStyle from '../../shared-style';
import { PROJECT_NAME_LENGTH_LIMIT } from "../../constants";

const STYLE_INPUT = {
  display: 'block',
  width: '100%',
  padding: '0 2px',
  fontSize: '13px',
  lineHeight: '1.25',
  color: SharedStyle.PRIMARY_COLOR.input,
  backgroundColor: SharedStyle.COLORS.white,
  backgroundImage: 'none',
  border: '1px solid rgba(0,0,0,.15)',
  outline: 'none',
  height: '30px',
};

export default class FormTextInput extends Component {

  constructor(props) {
    super(props);
    this.state = { focus: false };
    this.input = React.createRef(null);
  }

  componentDidMount() {
    if (this.input.current) {
      this.input.current.select();
    }
  }

  render() {
    let { style, ...rest } = this.props;

    let textInputStyle = { ...STYLE_INPUT, ...style };
    if (this.state.focus) textInputStyle.border = `2px solid ${SharedStyle.SECONDARY_COLOR.main}`;

    return (
      <input
        ref={this.input}
        onFocus={e => this.setState({ focus: true })}
        onBlur={e => this.setState({ focus: false })}
        // autoFocus
        style={textInputStyle}
        type="text"
        {...rest}
        maxLength={PROJECT_NAME_LENGTH_LIMIT}
      />
    );
  }
}

FormTextInput.defaultProps = {
  style: {}
};
