import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormNumberInput from '../../../style/form-number-input';
import convert from 'convert-units';
import ConfirmPopup from './confirm-popup';

const tableStyle = { width: '100%' };
const firstTdStyle = { width: '6em' };
const inputStyle = { textAlign: 'left' };
const Left = 0;
const Right = 180;
const Back = 90;
const Front = -90;

let newX, newY;

export default function ItemAttributesEditor({ element, onUpdate, attributeFormData, state, ...rest }, { translator }) {
  let [isPopupOpen, setPopupOpen] = useState(false);
  let name = attributeFormData.has('element') ? attributeFormData.get('element').get('name') : element.name;
  let renderedX = attributeFormData.has('element') ? attributeFormData.get('element').get('x') : element.x;
  let renderedY = attributeFormData.has('element') ? attributeFormData.get('element').get('y') : element.y;
  let renderedR = attributeFormData.has('element') ? attributeFormData.get('element').get('rotation') : element.rotation;

  let distBack, distFront, distLeft, distRight;
  if (element.distArray[0] == undefined) {
    for(let i = 0; i < 4; i++){
      switch(element.distArray.get(i).get(1)) {
        case Left:
          distLeft = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Right:
          distRight = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Back:
          distBack = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Front:
          distFront = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
      }
    }
  } else {
    for(let i = 0; i < 4; i++){
      switch(element.distArray[i][1]) {
        case Left:
          distLeft = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Right:
          distRight = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Back:
          distBack = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Front:
          distFront = convert(element.distArray[i][0]).from('cm').to('in');
          break;
      }
    }
  }

  const moveCabinet = () => {
    onUpdate('pos', { 'x': newX, 'y': newY });
  };

  const changeDistance = (oldVal, newVal, oldRot) => {
    let rotRad = (oldRot + renderedR) / 180 * Math.PI
    let dist;
    switch (oldRot) {
      case Left:
        dist = distRight;
        break;
      case Right:
        dist = distLeft;
        break;
      case Back:
        dist = distFront;
        break;
      case Front:
        dist = distBack;
        break;
      default:
        break;
    }
    newX = renderedX + convert((oldVal - newVal) * Math.cos(rotRad)).from('in').to('cm');
    newY = renderedY + convert((oldVal - newVal) * Math.sin(rotRad)).from('in').to('cm');
    if (dist + (oldVal - newVal) <= 0 || newVal < 0) {
      setPopupOpen(true);
      return;
    }
    onUpdate('pos', { 'x': newX, 'y': newY });
  };

  let handleConfirmLoad = value => {
    setPopupOpen(value);
  }

  return (
    <div>
      <ConfirmPopup
        visible={isPopupOpen}
        handleConfirmLoad={handleConfirmLoad}
        ok={moveCabinet}
      />
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={firstTdStyle}>Distance Left</td>
            <td>
              <FormNumberInput
                value={distLeft}
                disabled={distLeft ? false : true}
                onChange={event => changeDistance(distLeft, event.target.value, Left)}
                style={inputStyle}
                state={state}
                precision={2}
                {...rest}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Distance Right</td>
            <td>
              <FormNumberInput
                disabled={distRight ? false : true}
                value={distRight}
                onChange={event => changeDistance(distRight, event.target.value, Right)}
                style={inputStyle}
                state={state}
                precision={2}
                {...rest}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Distance Front</td>
            <td>
              <FormNumberInput
                disabled={distFront ? false : true}
                value={distFront}
                onChange={event => changeDistance(distFront, event.target.value, Front)}
                style={inputStyle}
                state={state}
                precision={2}
                {...rest}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Distance Back</td>
            <td>
              <FormNumberInput
                disabled={distBack ? false : true}
                value={distBack}
                onChange={event => changeDistance(distBack, event.target.value, Back)}
                style={inputStyle}
                state={state}
                precision={2}
                {...rest}
              />
            </td>
          </tr>
          <tr>
            <td style={firstTdStyle}>Rotation</td>
            <td>
              <FormNumberInput
                value={renderedR}
                onChange={event => onUpdate('rotation', event.target.value)}
                style={inputStyle}
                state={state}
                precision={2}
                {...rest}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

ItemAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

ItemAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
