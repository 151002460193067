import React, { useEffect, useState, useRef } from 'react';
// import { Modal, Button } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { Checkbox, FormControlLabel } from "@material-ui/core";
// import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CaretLeftFilled } from '@ant-design/icons';
import { CloseSquareFilled } from '@ant-design/icons';
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import { FaTimes} from 'react-icons/fa';
import { TutorialDialog } from './styles';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    minWidth: 550,
    minHeight: 335,
    '&:first-child': {
      paddingTop: 0,
    }
  }
}))(MuiDialogContent);

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const {  classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{color:" #FFF",
            fontSize: "18px",
            border:" 0px",
            borderRadius:" 3px",
            background: "#000",
            border:" 1px solid #000",
            padding:" 4px 4px 1px 4px",
            cursor:" pointer",
          marginTop:"10px", marginRight:"10px"}}
        >
         <FaTimes style={{marginBottom:"4px"}} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Modal(props) {

  const [tutorial_state, setTutorial_state] = useState(null);
  const [data] = useState([]);

  useEffect(() => {
    switch(tutorial_state) {
      case 0:
        props.closeFloorTB();
        break;
      case 1:
        props.toggleDoorStyle(false);
        props.openFloor();
        break;
      case 2:
        props.closeFloorTB();
        props.toggleDoorStyle(true);
        props.closeCabinetTB();
        break;
      case 3:
        props.openCabinet();
        props.toggleDoorStyle(false);
        document.getElementById ("toggle_2d_3d").style.zIndex = "";
        break;
      case 4:
        props.closeCabinetTB();
        document.getElementById ("toggle_2d_3d").style.zIndex = 1000;
        break;
      case 5:
        document.getElementById ("toggle_2d_3d").style.zIndex = "";
        props.closeFinishingTB();
        props.openAppliance();
        break;
      case 6:
        props.closeApplianceTB();
        props.onReviewQuoteClicked(false);
        props.openFinishing();
        break;
      case 7:
        props.closeFinishingTB();
        props.onReviewQuoteClicked(true);
        break;
    }
  }, [tutorial_state]);

  const handleNext = () => {
    setTutorial_state(tutorial_state + 1);
  };

  const handlePrev = () => {
    setTutorial_state(tutorial_state - 1);
  };

  useEffect(() => {
    data.push(
      <div style={{  width: '100%'}} key="1">
        <br/><br/>
        <div style={{width:"100%", display:"flex"}}>
          <div style={{ width: '20%' }}>
            <CaretLeftFilled
              style={{
                fontSize: '650%',
                color: '#1890ff',
                // marginLeft: '-20%',
                marginTop: '100%'
              }}
            />
          </div>
          <div style={{ width: '80%', marginTop: "-40px" }}>
            <br />
            <br />
            <p
              style={{
                color: 'white',
                fontWeight: '400',
                display: 'block',
                fontSize: '3em'
              }}
            >
              DIY Design Space
            </p>
            <p
              style={{
                color: 'white',
                fontWeight: '400',
                display: 'block',
                fontSize: '1.5em',
                marginTop: '-10%'
              }}
            >
              Quick start guide
            </p>
            <br />
            <br />
            <br />
            <p
              style={{
                color: 'white',
                fontWeight: '350',
                display: 'block',
                fontSize: '1.35em',
                marginTop: '-10%'
              }}
            >
              Follow the steps 1 through 6 in the left menu
              <br />
              to create your kitchen design
            </p>
          </div>
        </div>
      </div>
    );
    data.push(
        <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <img src="/assets/img/step2.jpg" align="left" width="75px" height="75px" />
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
            1. Make a floor plan
          </p>
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
                marginTop: '-10%',
        
            }}
          >
            Choose from a predefined floor space
            <br />
            or create your own from measurements.
            <br />
            Add windows and doors to finalize the space.
            </p></div>
        
      </div>
    );
    data.push(
      <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img src="/assets/img/step5.jpg" align="left" width="75px" height="75px" />
        
    
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
    2. Changing door styles<br />
            and colors
          </p>
          <br />

          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Explore the various door choices.
            <br />
            Use the change option to select
          <br />
         a new door and see it applied in your space.
          </p>
        </div>
      </div>
    );
    data.push(
      <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img src="/assets/img/step3.jpg" align="left" width="75px" height="75px" />
        
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
            3. Adding cabinets
          </p>

          <br />
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Start building your kitchen layout
            <br />
            by adding cabinets and accessories.
          </p>
        </div>
      </div>
    );
    data.push(
      <div style={{  width: '100%'}}>
      <div style={{height:"75px"}}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img  src="/assets/img/step4.jpg" align="left" width="75px"  />
        
    
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '2.6em'
            }}
          >
            View Space in 3D Mode
          </p>
          <br />
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Toggle the kitchen between
            <br />
            2D floor plan and 3D room view
          <br/>
            using the menu at the bottom
          <br/>
            of the screen.
          </p>
        </div>
      </div>
    );
    data.push(
      <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img src="/assets/img/step6.jpg" align="left" width="75px" height="75px" />
        
    
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
            4. Adding appliances
          </p>
          <br />
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Pick your appliances from the list
            <br />
            and add them to your design.
          </p>
        </div>
      </div>
    
    );
    data.push(
      <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img src="/assets/img/step7.jpg" align="left" width="75px" height="75px" />
        
    
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
            5. Finishing touches
          </p>
          <br />
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Further customize your kitchen by adding paint,
            <br />
            furniture, backsplash and more.
          </p>
        </div>
      </div>
    );
    data.push(
      <div style={{  width: '100%'}}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
        <img src="/assets/img/step8.jpg" align="left" width="75px" height="75px" />
        
    
          </Grid>
        </div>
        <div style={{ marginLeft: '15%', marginTop: "-40px" }}>
          <p
            style={{
              color: 'white',
              fontWeight: '400',
              display: 'block',
              fontSize: '3em'
            }}
          >
            6. Save/Submit project
          </p>
          <br />
          <br />
          <p
            style={{
              color: 'white',
              fontWeight: '350',
              display: 'block',
              fontSize: '1.35em',
              marginTop: '-10%'
            }}
          >
            Save your project, review it or submit for a quote.
            <br />
            You can also request design assistance at anytime
          <br/>
            from the top right menu on the main screen.
          </p>
        </div>
      </div>
    );
    setTutorial_state(0);
  }, []);

  return tutorial_state == null ? null : (
    <TutorialDialog
      open={props.isModalVisible}
      onClose={props.cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id = "tutorial_dialog"
    >
      <DialogTitle   onClose={props.cancel} /> 
     
      <DialogContent>
          {data[tutorial_state]}
      </DialogContent>  
      <DialogActions style={{ backgroundColor: '#41403F' }}>
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
          >
            <Grid item xs={1}>
              {!( tutorial_state == 0 ) && (
                <div >
                  <Button onClick={handlePrev} color="primary" style={{ minWidth: 80,marginLeft:"-35px" }}>
                    {'< Prev'}
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item xs={1}>
              {!( tutorial_state == 7 ) && (
                <div style={{ minWidth: 80, marginLeft:"10px"}}>
                  <Button onClick={handleNext} color="primary">
                    {' Next >'}
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item xs={1}>
              {' '}
              <div  style={{ width:"300%"}}>
                <Button
                  onClick={props.cancel}
                  color="primary"
                >
                  <u>Close Guide</u>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row-reverse">
              <FormControlLabel className="never-show-check"
                control={<Checkbox label="Never show again" color="primary" style = {{color: "white"}} inputRef={props.neverShowInput} />}
              label="Do not show this again"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </TutorialDialog>
  );
}
