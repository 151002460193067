import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { Tooltip } from '@material-ui/core/index';

export default class ControlButton extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = { hover: false };
  }

  render() {
    let { state, props } = this;
    let overlayStyle = {};
    if (state.hover)
      overlayStyle = { ...overlayStyle, 'display': 'block'}

    return (
      <Tooltip title={props.tooltip ? props.tooltip : ''} placement="top">
        <S.Control
          onMouseOver={event => this.setState({ hover: true })}
          onMouseOut={event => this.setState({ hover: false })}
          onClick={props.onClick}>
          {
            [
              props.title!== undefined ? <S.ControlTitle key={props.title}>{props.title}</S.ControlTitle>:null,
              props.icon !== undefined ? <S.ControlIcon key={props.icon}>{props.icon}</S.ControlIcon>:null
            ]
          }
        </S.Control>
      </Tooltip>
    )
  }
}

ControlButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
