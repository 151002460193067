import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const TutorialDialog = styled(Dialog)`
    z-index: 998 !important;
    .never-show-check {
        color: white;
    }
    .never-show-check > .MuiTypography-body1 {
        font-size: 0.7rem;
    }
    .never-show-check .MuiSvgIcon-root {
        width: 0.7em;
        height: 0.7em;
    }
    .never-show-check .MuiCheckbox-colorPrimary.Mui-checked {
        color: white;
    }
    .t_modal {
        z-index: 0 !important;
    }
    .MuiDialogTitle-root {
        background-color:#41403F    !important;
    }
    .MuiDialogContent-root {
        background-color: #41403F    !important;
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-variant: tabular-nums;
        line-height: 1.5715;
        background-color: #fff;
        -webkit-font-feature-settings: 'tnum';
                        font-feature-settings: 'tnum';
    }
    .MuiDialog-root p {
        margin-top: 0;
        margin-bottom: 1em;
    }
    h1, h2, h3, h4, h5, h6 {
        /* margin-top: 0; */
        /* margin-bottom: 0.5em; */
        color: rgba(255, 255, 255, 0.85);
        /* font-weight: 500; */
    }
    .MuiButton-textPrimary {
        color: white !important;
    }
    .MuiDialog-paperWidthSm {
        max-width: 550px !important;
        height: 380px !important;
    }
    .MuiDialog-paper {
        opacity: 0.84;
        overflow-y: unset !important;
    }
`;