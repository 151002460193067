import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

export let DoorsConcernedMenu = ({
  selected,
  data,
  onChange,
  getValue
}) => {

  return data.length ? (
    <Select
      className='submit-for-quote-form-item-button'
      value={selected}
      onChange={e => onChange(e.target.value)}
      MenuProps={{
        PaperProps: {
          style: {
            background: '#464647',
            color: 'white',
          }
        }
      }}
    >
      {data.map((item, key) => (
        <MenuItem key={key} value={getValue(item)}>
          <div className='submit-for-quote-form-item-button-icon'>
            <img height="20" src={item.thumbnail} className='submit-for-quote-form-item-button-img' />
          </div>
          <font size="2">{item.name}</font>
        </MenuItem>
      ))}
    </Select>
  ) : null;
}

DoorsConcernedMenu.defaultProps = {
  getValue: it => it.name
};