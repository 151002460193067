import styled from 'styled-components';
import * as SharedStyle from '../../../shared-style';

export const Control = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  background-color: #fff;
  color: white;
  border-radius:20px;
  margin-right: 15px;
  margin-top: 16px;
  right:260px;
  bottom:25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const ControlTitle = styled.span`
  margin:10px;
  font-size: 12px;
`
export const ControlIcon = styled.div`
  
  img {
      font-size: 1.4rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
      
      border-radius: 50%;
  }
  
  :hover {
    img {
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }
  }
`
export const Direction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: white;
  border-radius:32px;
  margin-right: 15px;
  right:260px;
  bottom:25px;
`
export const DirectionIcon = styled.div`
  position: absolute;
  color: #494949;
  :hover{
    color: #2c38de;
  }
`
export const DirectionSpinIcon = styled.div`
  position: absolute;
  color: #494949;
  img {
      font-size: 1.4rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
    }
  :hover{
    height: 40px;
    margin-bottom: 5px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
  }
`

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  margin-right: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img{
    font-size: 1.4rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12); 
    border-radius: 14px;
  }
  :hover{
    img {
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }
    
  }

`
export const ToggleIcon = styled.div`
  margin:5px;
  ${'' /* transition-duration: .3s; */}
  img {
      font-size: 1.4rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
      border-radius: 50%;
  }

`
export const ToggleConvertIcon = styled.div`
  margin:5px;
  transition-duration: .3s;
  :hover{
    img {
        font-size: 1.4rem;
        box-sizing: border-box;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
        border-radius: 50%;
    }
  }
`
