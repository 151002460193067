import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const DoorStyleOption = (props) => {

  const Div = styled.div`
    position: fixed;
    top: calc(50% - 150px);
    left: calc(50% - 200px);
    z-index: 200;
    background: #2b2b2ddf;
    align-items: space-around;
    justify-content: center;
    color: white;
    height: 140px;
  `
  const ButtonTitle1 = styled.span`
    position: absolute;
    padding-left: 20px;
    margin-top: 3px;
    font-size: 12px;
    cursor: pointer;
  `

  const ButtonTitle2 = styled.span`
    position: absolute;
    padding-left: 6px;
    margin-top: 3px;
    font-size: 12px;
    cursor: pointer;
  `

  const IconButton = styled.button`
    color: #FFF;
    font-size: 12px;
    border: 0px;
    border-radius: 3px;
    background: #000;
    border: 1px solid #000;
    padding: 4px 4px 1px 4px;
    cursor: pointer;
  `

  return (
    <div style={{ width: '100%', height: '100%', userSelect: 'none' }}>
      <Div>
        <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
          <div style={{ float: 'right', marginTop: '4px' }} onClick={() => props.handle(false)}>
            <IconButton><FaTimes /></IconButton>
          </div>
        </div>
        <div style={{ fontSize: 14, marginTop: 20 }}>
          <span style={{ margin: 20 }}>{props.content}</span>
        </div>
        <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative' }}>
        </div>
        <div style={{ display: 'flex', position: 'relative', marginTop: '20px', justifyContent: 'space-evenly' }}>
          <div style={{ width: '70px', marginTop: '15px', cursor: 'pointer' }} onClick={() => props.handle(true)}>
            <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
            <ButtonTitle1>
              {'All'}
            </ButtonTitle1>
          </div>
          <div style={{ width: '70px', marginTop: '15px', cursor: 'pointer' }} onClick={() => props.handle(false)}>
            <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/cancel_button.svg'} />
            <ButtonTitle2>
              {'Current'}
            </ButtonTitle2>
          </div>
        </div>
      </Div>
    </div>
  );
}

export default DoorStyleOption;
