import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ARRAY_3D_MODES
} from '../../../constants';
import * as S from './styles';
import { Tooltip } from '@material-ui/core/index';

export default class ToggleButton extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props } = this;
    let overlayStyle = {}
    // let activeStyle = {}
    let activeStyle = ARRAY_3D_MODES.includes(props.settingMode) ? {position: 'absolute', left: 32, top: -2}:{position: 'absolute', left: 2, top: 14};
    return (
      <Tooltip title={ARRAY_3D_MODES.includes(props.settingMode) ? "To 2D view" : "To 3D view"} placement="top">
        <S.Toggle id = "toggle_2d_3d" style={{...overlayStyle, color: "black"}} onClick={props.onClick}>
            {props.backgroundImage}
            <span style={{position: 'absolute', left: 10}}><strong>2D</strong></span>
            <span style={{position: 'absolute', left: 38}}><strong>3D</strong></span>
          {
            [
              props.icon !== undefined ? <S.ToggleIcon style={{...activeStyle}} key={props.icon}>{props.icon}</S.ToggleIcon>:null
            ]
          }
        </S.Toggle>
      </Tooltip>
    )
  }
}

ToggleButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
