import React from 'react';
import * as S from './styles';
import * as PS from '../styles';
import './styles.css';
import { FaTimes } from 'react-icons/fa';
import { emailValidation } from '../../../../utils/email-validator';

class DesignerAssistPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      emailCheck: true,
      phoneCheck: false,
      notes: '',
      first: false,
      second: false
    };
  }
  componentWillReceiveProps(newProps) {
    const email = localStorage.getItem('email');
    if (email !== null && newProps.visible !== this.props.visible)
      this.setState({email});
    const phone = localStorage.getItem('phone');
    if (phone !== null && newProps.visible !== this.props.visible)
      this.setState({phone});
  }
  _submit () {
    const {email, phone, emailCheck, phoneCheck, first, second, notes} = this.state;
    if (!emailCheck && !phoneCheck) {
      alert('Please select at least one.')
      return;
    }

    if ( email === '' && emailCheck || phone === '' && phoneCheck || !emailValidation(email)) {
      let msg;
      if (email === '' && emailCheck) msg = 'Type your email address';
      else if (!emailValidation(email) && emailCheck) msg = 'Type your valid email address';
      else if (phone === '' && phoneCheck) msg = 'Type your phone number';
      alert (msg);
      return;
    }
    var issue_type = (first ? "1" : "0") + "|" +  (second ? "2" : "0");
    this.props.designerAssist({email, phone, issue_type, notes});
    this.closePanel();
  }
  closePanel () {
    this.props.handleDesignerAssist(false);
    this.setState({email: '', phone: '', emailCheck: true, phoneCheck: false, first: false, second: false, notes: ''});
  }
  handleEmailChange(e) {
    this.setState({email: e.target.value});
  }
  handlePhoneChange(e) {
    this.setState({phone: (e.target.validity.valid) ? e.target.value : this.state.phone});
  }
  handleCheckChange  ( event ) {
    this.setState({ [event.target.value]: event.target.checked });
  };
  render() {
    let { visible } = this.props;
    let { email, phone, emailCheck, phoneCheck, notes, first, second } = this.state;

    return (
      <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
        <S.Div>
          <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
            <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
              <PS.IconButton><FaTimes /></PS.IconButton>
            </div>
          </div>
          <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
            Would you like to be fast-tracked to a Designer?<br/>
            What is the best way to reach you for immediate contact?
          </div>
          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <div style={{ paddingTop: 10 }}>
              <PS.DialogCheckbox
                checked={ this.state.emailCheck }
                onClick = { (e) => this.handleCheckChange (e) }
                value="emailCheck"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '0'
                }}
              >
                E-mail:
              </label>
              <input value={email} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handleEmailChange(e)} disabled={!emailCheck} />
            </div>
            <div style={{ paddingTop: 20 }}>
              <PS.DialogCheckbox
                checked={ this.state.phoneCheck }
                onClick = { (e) => this.handleCheckChange (e) }
                value="phoneCheck"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '0'
                }}
              >
                Phone:
              </label>
              <input value={phone} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handlePhoneChange(e)} disabled={!phoneCheck} pattern={"[0-9]*"} />
            </div>
          </div>
          <div style={{ fontSize: 14, marginTop: 10, marginLeft: 80, alignItems: 'center'}}>
            Specify the type of issue you are having
          </div>
          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative', marginLeft: '80px' }}>
            <div style={{ paddingTop: 12 }}>
              <PS.DialogCheckbox
                checked={ this.state.second }
                onClick = { (e) => this.handleCheckChange (e) }
                value="second"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '0'
                }}
              >
                I don't have measurements
              </label>
            </div>
            <div style={{ paddingTop: 12 }}>
              <PS.DialogCheckbox
                checked={ this.state.first }
                onClick = { (e) => this.handleCheckChange (e) }
                value="first"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
              <label
                className="container"
                style={{
                  paddingLeft: '0'
                }}
              >
                Other
              </label>
            </div>
            <div style={{ paddingTop: '0px', width: '340px' }}>
              <div style={{ marginTop: '8px' }}>
                <textarea value={notes} style={{ width: '100%', height: '100%', resize: 'none' }} disabled={!first} rows={10} onChange={e => this.setState({ notes: e.target.value })} />
              </div>
            </div>
          </div>
          <div style={{ width: '70px', marginLeft: '350px', marginTop: '10px', cursor: 'pointer' }} onClick={() => this._submit()}>
            <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
            <S.ButtonTitle>
              {'SUBMIT'}
            </S.ButtonTitle>
          </div>
        </S.Div>
      </div>
    )
  }
}


export default DesignerAssistPopup
