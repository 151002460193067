import styled from 'styled-components';
import { Checkbox } from "@material-ui/core";

// Common Styles

export const UnAvailableText = styled.div`
  position: absolute; 
  width: 100%; 
  height: 100%; 
  background-color: #696969; 
  opacity: 0.8; 
  top: 0; 
  color: white; 
  display: flex; 
  font-size: 16px;
  align-Items: center; 
  justify-Content: center; 
  cursor: no-drop; 
  user-Select: none;
`
export const IconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`
export const ActiveIconButton = styled.button`
  color: #FFF;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #0f8a61;
  border: 1px solid #0f8a61;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`
// Main Styles
export const Wrapper = styled.div`
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const TitleBar = styled.div`
  width: 100%;
  height: 30px;
  background: #1183bb;
  padding: 0px 8px 0px 8px;
  clear: both;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const Title = styled.div`
  float: left;
  line-height: 30px;
  color: #FFF;
  font-size: 12px;
`
export const TitleBarIconButton = styled.div`
  float: right;
  margin-left: 10px;
  margin-top: 4px;
  cursor: pointer;
`
export const ContentWrapper = styled.div`
  clear: both;
`
export const ContentObjectWrapper = styled.div`
  max-height: 70vh;
  overflow: scroll;
  background: white;
  clear: both;
  padding: 10px 10px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const ContentObjectCeilHeight = styled.div`
  width: 210px;
  background: rgba(250,252,251,1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  height: 43px;
  margin-top: 13px;
  padding-top: 4px;
`;
export const SearchForm = styled.div`
  position: relative;
  margin: 0px 5px;
`
export const SearchInput = styled.input`
  background: #FFF;
  border: 0px;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  padding-left: 24px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px;
  }
`
export const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 5px;
  color: #666;
  cursor: pointer;
  font-size: 15px;
`
export const TitlesRow = styled.div`
  display: grid;
`
export const RecentTitle = styled.div`
  color: #FFF;
  margin: 14px 0px 19px;
`
export const RecentStyles = styled.div`
`
export const AddStyleButtonWrapper = styled.div`
  cursor: pointer;
  color: #FFF;
  button {
    float: left;
  }
  span {
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    font-size: 10px;
  }
`
export const SubTitle = styled.div`
  color: #000;
  padding-top: 10px;
`
export const ChildTitle = styled.div`
  color: #000;
  padding-bottom:10px;
`
export const ChildTitle1 = styled.div`
  color: #000;
  padding-bottom:10px;
  height: 26.5px;
`
export const CabinetTitle = styled.div`
  color: #000;
  font-weight: 800;
`
export const PropteryValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-bottom:10px;
  span {
    font-weight:100;
  }
`
export const PositionValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-top:10px;
  span {
    font-weight:100;
  }
`
export const Diver = styled.div`
  border-bottom: 2px solid;

`
export const FurnitureBoxTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
  font-size: 11px;
`
export const ClearFix = styled.div`
  clear: both;
  height: 0px;
`
export const Image = styled.div`
  text-align: center;
  div{
    width: 66px;
    height: 70px;
  }
  img {
    width: 66px;
    height:70px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  :hover{
    img {
      width:70px;
      height:75px;
    }
  }
`
export const SelectedWrapper = styled.div`
  width: 210px;
  background: rgba(250, 252, 251, 1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const SelectedBox = styled.div`
  display: flex;
  justify-content: center;
  width: 190px;
  background-color: dimgrey;
  img {
    width: 100px;
    height: 100px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  // :hover{
  //   img {
  //     width:120px;
  //   }
  // }
`
//////
export const CategoryContentWrapper = styled.div`
  float: left;
  width: 100%;
  min-width: 185px;
  overflow-y: auto;
`
export const ProductsContentWrapper = styled.div`
  float: left;
  border-left: 2px solid #2f2f2f;
  overflow-y: auto;
`
export const ItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
`
export const testJsonItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  :hover {
    color: rgb(28,166, 252);
    cursor:pointer;
  }
`
export const ProductsRow = styled.div`
  display: block;
  flex-wrap: wrap;
  align-items: streatch;
  a :hover {
    color: rgb(28,166, 252);
    cursor:pointer;
  }
`
export const ButtonTitle = styled.span`
  position: absolute;
  text-align: center;
  top: 42%;
  left: 0px;
  width: 100%;
  color: #FFF;
  transform: translate(0%, -50%);
  font-size: 12px;
`

export const DialogCheckbox = styled (Checkbox)`
  padding: 0px !important;
  font-size: 14px !important;
  color: aliceblue !important;
  margin-bottom: 4px !important;
`

;

