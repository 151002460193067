import React, { useState } from 'react';
import { ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';
import {returnReplaceableDeepSearchType} from '../../../../components/viewer2d/utils'


export default function ReviewItem({catalog, itemSKU, categoryData, products, item, count}) {
  let review;
  let doorColor = item.doorStyle;
  if(catalog !== undefined) {
    review = catalog.elements[item.type];
    if(!review) review = catalog.elements[returnReplaceableDeepSearchType(item.type)]
    if(item.doorStyle.size){
      doorColor = item.doorStyle.toJS();
    }
    let product = products[itemSKU];
    return (
      <div style={{display: 'flex', alignItems: 'center', padding: '5px 15px 5px', borderBottom: '1px solid #2f2f2f', minWidth: 460, color: '#FFF'}}>
        <div style={{ width: '100%'}}>
          <div style={{ display: 'flex', alignItems:'center', marginTop:'5px',marginBottom:'5px'}}>
            <img width={40} name={"cabinetImage"} src={review.img} />
            {/* <span style={{marginLeft: 23, fontSize: 12,}}>{name}</span> */}
            <div style={{width: '90%'}}>
              <table style = {{width:'100%', paddingLeft: '15px', fontSize: '10px'}}>
                <thead>
                  <tr>
                    <td style = {{ width: "50%" }}><strong>{review.long_name}</strong></td>
                    <th style = {{ width: "10%" }}><strong>Qty</strong></th>
                    <th style = {{ width: "15%" }}><strong>Regular Price</strong></th>
                    <th style = {{ width: "15%" }}><strong>Discounted Price</strong></th>
                    <th style = {{ width: "15%" }}><strong>Subtotal</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>Full SKU:</strong>{' '}
                        {itemSKU}
                        <br />
                        <strong>Door Style:</strong> {review.obj.door_style}{' '}
                        <br />
                        <strong>Door Color:</strong> {doorColor.name} <br />
                      </p>
                    </td>
                    <th>{count || 1}</th>
                    <th
                      style={{
                        color:
                          product && product.discounted_price !== null
                            ? 'lightgray'
                            : 'black',
                        textDecoration:
                          product && product.discounted_price !== null
                            ? 'line-through'
                            : 'none'
                      }}
                    >
                      {product && product.regular_price ? `$${product && product.regular_price}` : 0}
                    </th>
                    <th style={{ color: 'lightgreen' }}>
                      {product && product.regular_price !== 0 && product.discounted_price === product.regular_price &&
                        <div style={{marginTop: "-1.2rem", marginBottom:"0.2rem" }}><Tooltip title="Please contact us for Sale price" ><ExclamationCircleFilled style={{ fontSize: 15, fontWeight: 'bold', color: 'yellow' }} /></Tooltip><br /></div>
                      }
                      {(!!!product || (product && product.regular_price === 0 && product.regular_price === product.discounted_price)) &&
                        <div style={{marginTop: "-1.2rem", marginBottom:"0.2rem" }}><Tooltip title="This item is not available in the finish you have selected. Please contact us for alternate options in the finish you want."><QuestionCircleFilled style={{ fontSize: 15, fontWeight: 'bold', color: 'yellow' }} /></Tooltip><br /></div>
                      }
                      {product && product.discounted_price
                        ? `$${product && product.discounted_price}`
                        : 0}
                    </th>
                    <th>
                      {product && product.discounted_price
                        ? `$${
                            product && product.discounted_price * (count || 1)
                          }`
                        : 0}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )

  }else{
    null
  }

}
