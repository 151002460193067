import styled from 'styled-components';

export const Div = styled.div`
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - 200px);
    z-index: 200;
    background: #2b2b2ddf;
    align-items: space-around;
    justify-content: center;
    color: white;
    width: 500px;
    height: 510px;
`

export const ButtonTitle = styled.span`
  position: absolute;
  padding-left: 8px;
  margin-top: 3px;
  font-size: 12px;
  cursor: pointer;
`