import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../shared-style';
import * as S from './styles';
import { Tooltip } from '@material-ui/core/index';

export default class DirectionButton extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props } = this;
    let overlayStyle = {}
    // overlayStyle = props.active ? {...overlayStyle,'background': SharedStyle.TOOLBAR_COLOR.active}:{...overlayStyle};
    let title, placement;
    switch(props.tooltip){
      case 'Right':
        title = 'Right';
        placement = 'right';
        break;
      case 'Left':
        title = 'Left';
        placement = 'left';
        break;
      case 'Top':
        title = "Up";
        placement = 'top';
        break;
      default: // Bottom
        title = 'Down';
        placement = 'bottom';
        break;
    }
    return (
      <Tooltip title={title} placement={placement}>
        <S.DirectionIcon style={props.style} onMouseDown={props.onClick} key={props.icon}>{props.icon}</S.DirectionIcon>        
      </Tooltip>
    )
  }
}

DirectionButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
