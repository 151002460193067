import React from 'react';
import PropTypes from 'prop-types';
import If from '../../utils/react-if';
import RulerDist from './rulerDist';
import convert from 'convert-units';
import { GeometryUtils } from '../../utils/export';
const STYLE_LINE = {
  fill: "#0096fd",
  stroke: "#0096fd"
};

const STYLE_CIRCLE = {
  fill: "#0096fd",
  stroke: "#0096fd",
  cursor: "ew-resize"
};

const STYLE_CIRCLE2 = {
  fill: "none",
  stroke: "#0096fd",
  cursor: "ew-resize"
};
export default function Item({state, layer, item, scene, catalog}, {itemsActions}) {
  let {x, y, rotation} = item;
  let { showMeasure } = scene;
  let {vertices} = layer;
  let layerID = scene.selectedLayer;
  let element = catalog.getElement(item.type);
  let allLines;
  let curiteminfo;
  let allLineRects;
  let allItemRect;
  let width, height;
  let val = {pos:{x:item.x, y:item.y}, rotRad:item.rotation / 180 * Math.PI};
  let tempWidth = item.properties.get('width');
  let tempHeight = item.properties.get('depth');
  if (tempWidth || tempHeight) {
    width = convert(tempWidth.get('_length')).from(tempWidth.get('_unit')).to('cm');
    height = convert(tempHeight.get('_length')).from(tempHeight.get('_unit')).to('cm');
  } else {
    width = convert(element.info.sizeinfo.width).from('in').to('cm');
    height = convert(element.info.sizeinfo.depth).from('in').to('cm');
  }
  let angle = rotation + 90;
  val.size = {width, height};
  function point(x, y) {
    return {x, y};
  }
  
  function getCalcRectFromItem(items) {
    let x = items.pos.x;
    let y = items.pos.y;
    let w = items.size.width / 2;
    let h = items.size.height / 2;
    let rotRad = items.rotRad;
    let mh = 3*h/4;
    let mx = (x - w * Math.cos(rotRad)) - (mh*Math.sin(rotRad));
    let my = (y - w * Math.sin(rotRad)) + (mh*Math.cos(rotRad));
    let m2x = (x + w * Math.cos(rotRad)) - (mh*Math.sin(rotRad));
    let m2y = (y + w * Math.sin(rotRad)) + (mh*Math.cos(rotRad));
    let m3x = x - h * Math.sin(rotRad);
    let m3y = y + h * Math.cos(rotRad);
    let m1x = x + h * Math.sin(rotRad);
    let m1y = y - h * Math.cos(rotRad);
    let x0 = mx + h * Math.sin(rotRad);
    let y0 = my - h * Math.cos(rotRad);
    let x3 = mx*2 - x0;
    let y3 = my*2 - y0;
    let x1 = x*2 - x3;
    let y1 = y*2 - y3;
    let x2 = x*2 - x0;
    let y2 = y*2 - y0;
    return {rectCenterPoint:[[point(mx,my), 180], [point(m1x,m1y), -90], [point(m2x,m2y), 0], [point(m3x,m3y), 90]]};
  }

  function getAllItems() {

    let rectarray = [];
    let currentItem;
    let selectedItem;

    if ( layer.selected.items.size > 0 ) {
      selectedItem = layer.getIn(['items', layer.selected.items.get(0)]);
      let catid = selectedItem.type;
      let cat = catalog.elements[catid];
      if( cat === undefined || cat === null )
        cat = catalog.getIn(['elements', catid]);
      currentItem = {
        selectedItem,
        cat
      }
    }
  
    layer.items.forEach(item => {
      let val = {pos:{x:item.x, y:item.y}, rotRad:item.rotation / 180 * Math.PI};
      let catid = item.type;
      let cat = catalog.elements[catid];
      let width = convert(item.properties.getIn(['width', '_length'])).from('in').to('cm');
      let height = convert(item.properties.getIn(['depth', '_length'])).from('in').to('cm');
      // let width = cat.info.sizeinfo.width;
      // let height = cat.info.sizeinfo.depth;
      val.size = {width, height};
      let otherItem = {
        item,
        cat
      }

      // if (!GeometryUtils.needSnap(currentItem, otherItem)) {
      //   return;
      // }

      if (!item.selected) {
        let x = val.pos.x;
        let y = val.pos.y;
        let rotRad = val.rotRad;
        let w = val.size.width / 2;
        let h = val.size.height / 2;
        let mx = x - w * Math.cos(rotRad);
        let my = y - w * Math.sin(rotRad);
        let x0 = mx + h * Math.sin(rotRad);
        let y0 = my - h * Math.cos(rotRad);
        let x3 = mx*2 - x0;
        let y3 = my*2 - y0;
        let x1 = x*2 - x3;
        let y1 = y*2 - y3;
        let x2 = x*2 - x0;
        let y2 = y*2 - y0;
        rectarray.push({'rect':[point(x0,y0), point(x1,y1) ,point(x0,y0), point(x1,y1)]});
        rectarray.push({'rect':[point(x1,y1), point(x2,y2), point(x1,y1), point(x2,y2)]});
        rectarray.push({'rect':[point(x2,y2), point(x3,y3), point(x2,y2), point(x3,y3)]});
        rectarray.push({'rect':[point(x3,y3), point(x0,y0), point(x3,y3), point(x0,y0)]});
      }
    });

    // layer.holes.forEach(hole => {
    //   let val = {pos:{x:hole.x, y:hole.y}, rotRad:hole.rotation};
    //   let catid = hole.type;
    //   let cat = catalog.elements[catid];
    //   let width = hole.properties.getIn(['width']).getIn(['length']);
    //   let height = hole.properties.getIn(['height']).getIn(['length']);
    //   val.size = {width, height};
    //   let otherItem = {
    //     hole,
    //     cat
    //   }

    //   // if (!GeometryUtils.needSnap(currentItem, otherItem)) {
    //   //   return;
    //   // }

    //   if (!hole.selected) {
    //     let x = val.pos.x;
    //     let y = val.pos.y;
    //     let rotRad = val.rotRad;
    //     let w = val.size.width / 2;
    //     let mx = x - w * Math.cos(rotRad);
    //     let my = y - w * Math.sin(rotRad);
    //     let kx = x + w * Math.cos(rotRad);
    //     let ky = y + w * Math.sin(rotRad);
    //     let x0 = mx - 10 * Math.sin(rotRad);
    //     let y0 = my + 10 * Math.cos(rotRad);
    //     let x3 = mx + 10 * Math.sin(rotRad);
    //     let y3 = my - 10 * Math.cos(rotRad);
    //     let x1 = kx - 10 * Math.sin(rotRad);
    //     let y1 = ky + 10 * Math.cos(rotRad);
    //     let x2 = kx + 10 * Math.sin(rotRad);
    //     let y2 = ky - 10 * Math.cos(rotRad);
    //     rectarray.push({'rect':[point(x0,y0), point(x1,y1) ,point(x0,y0), point(x1,y1)]});
    //     rectarray.push({'rect':[point(x1,y1), point(x2,y2), point(x1,y1), point(x2,y2)]}); // right
    //     rectarray.push({'rect':[point(x2,y2), point(x3,y3), point(x2,y2), point(x3,y3)]}); // front
    //     rectarray.push({'rect':[point(x3,y3), point(x0,y0), point(x3,y3), point(x0,y0)]}); // left
    //   }
    // });
    return {others: rectarray};
  }

  allItemRect = getAllItems();
  allLines = GeometryUtils.getAllLines(layer);
  allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
  let allRect = allLineRects.concat(allItemRect.others);
  curiteminfo = getCalcRectFromItem(val);

  /**
   * 
   * @param x y position
   * @param y x position
   * @param rotRad item's rotation in radian
   */
  let getDistant = (x, y, rotRad) => {
    let center_h = 3*height/8;
    let center_x = x;
    let center_y = y;
    let center_x1 = x - center_h*Math.sin(rotRad);
    let center_y1 = y + center_h*Math.cos(rotRad);
    let PointArray = [];
    curiteminfo.rectCenterPoint.forEach(centerpoint => {
      let comparelength = [];
      let a;
      let RectLineFuction;
      if(centerpoint[1] === 180 || centerpoint[1] === 0)
        RectLineFuction = GeometryUtils.linePassingThroughTwoPoints(centerpoint[0].x, centerpoint[0].y, center_x1, center_y1);
      else
        RectLineFuction = GeometryUtils.linePassingThroughTwoPoints(centerpoint[0].x, centerpoint[0].y, center_x, center_y);
        allRect.forEach(linerect => {
        let p0 = GeometryUtils.clone_point(linerect.rect[2]);
        let p1 = GeometryUtils.clone_point(linerect.rect[3]);
        let lineFunction = GeometryUtils.linePassingThroughTwoPoints(p0.x, p0.y, p1.x, p1.y);
        let coordinatePoint = GeometryUtils.twoLinesIntersection(lineFunction.a, lineFunction.b, lineFunction.c, RectLineFuction.a, RectLineFuction.b, RectLineFuction.c);
        if(coordinatePoint !== undefined ) {
          if(GeometryUtils.pointsDistance(p0.x, p0.y, p1.x, p1.y) > GeometryUtils.pointsDistance(p0.x, p0.y, coordinatePoint.x, coordinatePoint.y) && GeometryUtils.pointsDistance(p0.x, p0.y, p1.x, p1.y) > GeometryUtils.pointsDistance(p1.x, p1.y, coordinatePoint.x, coordinatePoint.y)){
            if(GeometryUtils.pointsDistance(coordinatePoint.x, coordinatePoint.y, center_x, center_y) > GeometryUtils.pointsDistance(centerpoint[0].x, centerpoint[0].y, coordinatePoint.x, coordinatePoint.y))
             {
              comparelength.push(GeometryUtils.pointsDistance(centerpoint[0].x, centerpoint[0].y, coordinatePoint.x, coordinatePoint.y));
                a = Math.min.apply(null,comparelength);
              }
            }
          }
        })
        PointArray.push([a, centerpoint[1]]);
    })
    return {PointArray};
  };

  let nw = width/2;
  let nh = height/2;

  let { PointArray } = getDistant(x, y, val.rotRad);
  PointArray.forEach((pointElement, index) => {
    if(pointElement[0] == undefined)
      PointArray[index][0] = 0;
  });
  setImmediate(() => itemsActions.storeDistArray(layer.id, item.id, PointArray));
  let itemId = item.id;
  let renderedRuler = [];
  let ep = 0.1;
  if(item.selected) {
    PointArray.forEach((element, key) => {
      let itemDistanceFromLine = element[0];
      let length = itemDistanceFromLine;
      if(itemDistanceFromLine > ep || itemDistanceFromLine < -ep ) {
        renderedRuler.push(
        <g 
          data-element-root
          data-prototype = 'rulerDist'
          data-id = {item.id}
          data-selected = {item.selected}
          data-layer = {layer.id}
          key = {key}
          data-length = {length}
          data-direct = {element[1]}
        >
          <RulerDist key={key} unit={scene.unit} rulerUnit={scene.rulerUnit} length={itemDistanceFromLine} angle={rotation} rotation={element[1]} transform={`translate(${ element[1] === 180 ? -nw: element[1] === 0 ? nw : 0 }, ${ element[1] === 90 ? nh : element[1] === -90 ? -nh : 3*nh/4}) rotate(${element[1]}, 0, 0)`}/>
        </g>);
      }
    })
  }

  let renderedItem = element.render2D.call(element, item, layer, scene);
  let isSmall = false;
  if(width<40) isSmall = true;
  let parts = [];
  if (item.selected)
  parts = [<g key={0} data-element-root
    data-prototype={item.prototype}
    data-id={item.id}
    data-selected={item.selected}
    data-layer={layer.id}
    data-part="rotation-anchor"
    style={{cursor : 'w-resize'}}
 >
   <image transform={`scale(1,-1)`} href="/assets/img/svg/rotate_object_clockwise.svg" x={-nw-30} y={nh - 10} height="20" width="20" />
   <image transform={`scale(1,-1)`} href="/assets/img/svg/rotate_object_counterclockwise.svg" x={nw+10} y={nh - 10} height="20" width="20" />
 </g>,
 <g key={1} transform={`translate(${-width / 2},${height / 2})`} style={{cursor:'pointer'}}  data-element-root data-prototype={item.prototype}
    data-id={item.id}
    data-selected={item.selected}
    data-layer={layer.id} data-part='remove'>
 <image transform={isSmall?`rotate(180, ${width - 10} ,26) `:`rotate(180, 10 ,15) `} href="/assets/img/svg/2d_delete_object1.svg" x={isSmall?width-20:"0"} y="5" height="20" width="20"/>
 </g>,
 <g key={2} transform={`translate(${-width / 2},${height / 2})`} style={{cursor:'pointer'}} data-element-root data-prototype={item.prototype}
    data-id={item.id}
    data-selected={item.selected}
    data-layer={layer.id} data-part='duplicate'>
 <image transform={`rotate(180, ${width - 10} ,15)`} href="/assets/img/svg/duplicate_object_right.svg" x={width-20} y="5" height="20" width="20"/>
</g>];
  
  return (
    <g
      data-element-root
      data-prototype={item.prototype}
      data-id={item.id}
      data-selected={item.selected}
      data-layer={layer.id}
      style={item.selected ? {cursor : "move"} : {}}>
      {renderedItem}
      <g transform={`translate(${x},${y}) rotate(${rotation})`} style={{cursor : 'initial'}}>
        {parts}
        {showMeasure?renderedRuler:null}
      </g>
    </g>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};

Item.contextTypes = {
  itemsActions: PropTypes.object.isRequired,
};
