import React from 'react';
import PropTypes from 'prop-types';
import { GeometryUtils } from '../../utils/export';
import Ruler from './ruler';
import convert from 'convert-units';
import { LINE_THICKNESS } from '../../../src/constants';

const STYLE = {
  stroke: "#506ab3",
  strokeWidth: "1px"
};
const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "10px",
  fontWeight: "bold",
};

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export default function Line({ line, allLine, layer, scene, num, catalog }) {
  let {showMeasure} = scene;
  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));
  if (vertex0.id === vertex1.id || GeometryUtils.samePoints(vertex0, vertex1)) return null; //avoid 0-length lines

  let { x: x1, y: y1 } = vertex0;
  let { x: x2, y: y2 } = vertex1;

  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
    ({ x: x1, y: y1 } = vertex1);
    ({ x: x2, y: y2 } = vertex0);
  }

  let length = GeometryUtils.pointsDistance(x1, y1, x2, y2);
  let rightHoleLength, leftHoleLength, holeLength;
  let angle = GeometryUtils.angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2);
  let renderedHoles = line.holes.map(holeID => {
    let hole = layer.holes.get(holeID);
    let startAt = length * hole.offset;
    let renderedHole = catalog.getElement(hole.type).render2D(hole, layer, scene);
    if(compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x){
      return (
        <g
          key={holeID}
          transform={`translate(${startAt}, -3.5)`}
          data-element-root
          data-prototype={hole.prototype}
          data-id={hole.id}
          data-selected={hole.selected}
          data-layer={layer.id}
        >
          {renderedHole}
        </g>
      );
    }else{
      return (
        <g
          key={holeID}
          transform={`translate(${startAt}, 3.5)`}
          data-element-root
          data-prototype={hole.prototype}
          data-id={hole.id}
          data-selected={hole.selected}
          data-layer={layer.id}
        >
          {renderedHole}
        </g>
      );
    }
    
  });

  let thickness = LINE_THICKNESS;
  let half_thickness = thickness / 2;
  let renderedLine = catalog.getElement(line.type).render2D(line, layer);
  // let renderedRuler = line.selected ?
  //   <Ruler unit={scene.unit} length={length} transform={`translate(0, ${half_thickness + 10} )`}/> : null;
  let holeTemp = [];
  let renderedRuler = [];
  let renderedAllRuler = [];
  let renderedHoleRuler = [];
  let renderedLeftHoleRuler = [];
  let renderedRightHoleRuler = [];
  let holeRuler = [0, length];
  line.holes.forEach(holeID => {
    let hole = layer.holes.get(holeID);
    let startAt = length * hole.offset;
    let holeWidth = hole.properties.toJS().width.length;
    if( hole.selected ) {
      holeRuler.push(startAt - holeWidth / 2);
      holeRuler.push(startAt + holeWidth / 2);
      holeTemp = hole;
    }
  });
  holeRuler = holeRuler.sort((a, b) => a - b);

  let allRuler = [0], holeData = [];
  let lineHoles = line.holes.sortBy(holeID => {
    let hole = layer.holes.get(holeID);
    return hole.offset;
  });
  if (line.selected) {
    lineHoles.forEach(holeID => {
      let hole = layer.holes.get(holeID);
      let startAt = length * hole.offset;
      let holeWidth = hole.properties.toJS().width.length;
      allRuler.push(startAt - holeWidth / 2);
      allRuler.push(startAt + holeWidth / 2);
      holeData[
        `${startAt - holeWidth / 2}_${startAt + holeWidth / 2}`
      ] = holeID;
    });
    allRuler.push(length);
  }

  if (holeRuler.length > 2) {
    leftHoleLength = holeRuler[1] - holeRuler[0];
    holeLength = holeRuler[2] - holeRuler[1];
    rightHoleLength = holeRuler[3] - holeRuler[2];
    if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
      renderedRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${half_thickness - 40} )`} />);
      renderedLeftHoleRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={leftHoleLength} transform={`translate(${holeRuler[0]}, ${half_thickness - 25} )`} />);
      renderedHoleRuler.push(<Ruler key={1} unit={scene.unit} rulerUnit={scene.rulerUnit} length={holeLength} transform={`translate(${holeRuler[1]}, ${half_thickness - 25} )`} />);
      renderedRightHoleRuler.push(<Ruler key={2} unit={scene.unit} rulerUnit={scene.rulerUnit} length={rightHoleLength} transform={`translate(${holeRuler[2]}, ${half_thickness - 25} )`} />);
    }else{
      renderedRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${half_thickness + 30} )`} />);
      renderedLeftHoleRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={leftHoleLength} transform={`translate(${holeRuler[0]}, ${half_thickness + 10} )`} />);
      renderedHoleRuler.push(<Ruler key={1} unit={scene.unit} rulerUnit={scene.rulerUnit} length={holeLength} transform={`translate(${holeRuler[1]}, ${half_thickness + 10} )`} />);
      renderedRightHoleRuler.push(<Ruler key={2} unit={scene.unit} rulerUnit={scene.rulerUnit} length={rightHoleLength} transform={`translate(${holeRuler[2]}, ${half_thickness + 10} )`} />);
    }
  } else{
    if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
      renderedRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${half_thickness + ((line.selected && allRuler.length > 2) ? -40 : -30)})`} />);
    }else{
      renderedRuler.push(<Ruler key={0} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${half_thickness + ((line.selected && allRuler.length > 2) ? 30 : 10)})`} />);
    }
  }

  if (allRuler.length > 2) {
    allRuler.forEach((rulerData, index) => {
      if (!Number.isNaN(allRuler[index + 1] - allRuler[index])) {
        let dataProtoType = 'ruler';
        let dataId = holeData[`${allRuler[index]}_${allRuler[index + 1]}`];
        if (!dataId) {
          let holeID1 = holeData[`${allRuler[index - 1]}_${allRuler[index]}`];
          let holeID2 =
            holeData[`${allRuler[index + 1]}_${allRuler[index + 2]}`];
          if (holeID1 && holeID2) {
            dataId = `${holeID1},${holeID2}`;
            dataProtoType = 'twoHoleRuler';
          } else if (holeID1) {
            dataId = holeID1;
            dataProtoType = 'rightHoleRuler';
          } else if (holeID2) {
            dataId = holeID2;
            dataProtoType = 'leftHoleRuler';
          }
        } else {
          dataProtoType = 'rulerHole';
        }
        renderedAllRuler.push(
          <g
            key={`allRuler_${index}`}
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
            data-element-root
            data-prototype={dataProtoType}
            data-id={dataId}
            data-selected={line.selected}
            data-layer={layer.id}
            data-length={allRuler[index + 1] - allRuler[index]}
          >
            {showMeasure ? (
              <Ruler
                key={`allRuler_${index}`}
                unit={scene.unit}
                rulerUnit={scene.rulerUnit}
                length={allRuler[index + 1] - allRuler[index]}
                transform={`translate(${allRuler[index]}, ${
                  half_thickness + compareVertices(vertex0, vertex1) >= 0 &&
                  vertex0.x !== vertex1.x
                    ? -15
                    : 15
                } )`}
              />
            ) : null}
          </g>
        );
      }
    });
  }

  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x){
    return (
      <g>
        {showMeasure ? renderedAllRuler : null}
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'ruler'}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={length}
        >
          { showMeasure ? renderedRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'leftHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={leftHoleLength}
        >
          { showMeasure ? renderedLeftHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rulerHole'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={holeLength}
        >
          { showMeasure ? renderedHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rightHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={rightHoleLength}
        >
          { showMeasure ? renderedRightHoleRuler : null }
        </g>
        <g
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          style={line.selected ? { cursor: 'move' } : {}}
        >
          { renderedLine }
          <g 
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          >
            <text x={length/2} y={7} transform={`scale(1,-1) rotate(${0})`} style={STYLE_TEXT}>{num}</text>
            {renderedHoles}
          </g>
        </g>
      </g>
    );
  }else{
    return (
      <g>
        {showMeasure ? renderedAllRuler : null}
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'ruler'}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={length}
        >
          { showMeasure ? renderedRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'leftHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={leftHoleLength}
        >
          { showMeasure ? renderedLeftHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rulerHole'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={holeLength}
        >
          { showMeasure ? renderedHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rightHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={rightHoleLength}
        >
          { showMeasure ? renderedRightHoleRuler : null }
        </g>
        <g
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          style={line.selected ? { cursor: 'move' } : {}}
        >
          { renderedLine }
          <g 
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          >
            <text x={length/2} y={0} transform={`scale(1,-1) rotate(${0})`} style={STYLE_TEXT}>{num}</text>
            {renderedHoles}
          </g>
        </g>
      </g>
    );
  }
  

}

Line.propTypes = {
  line: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
